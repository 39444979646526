import React from 'react';

export const VideoCall: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        /*  zIndex: 100, */
        borderWidth: 2,
        position: 'absolute',
        top: 0,
        right: 'left',
      }}
    >
      <iframe
        className={'iframe'}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
        allow='camera;microphone'
        frameBorder='1'
        src='https://client.consolto.com/expert/visit.helgeland?start=videocall'
        title='Kontakt oss med videosamtale'
      />
    </div>
  );
};
