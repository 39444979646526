import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import image1 from '../assets/images/1.jpg';
import image2 from '../assets/images/2.jpg';
import image3 from '../assets/images/3.jpg';
import image4 from '../assets/images/4.jpg';
import image5 from '../assets/images/5.jpg';
import image6 from '../assets/images/6.jpg';
import image7 from '../assets/images/7.jpg';
import image8 from '../assets/images/8.jpg';
import image9 from '../assets/images/9.jpg';
import image10 from '../assets/images/10.jpg';

export const SlideShow = () => {
  const images: string[] = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
  ];
  const VerticalImageStack = [];

  const shuffledImages = images.sort((a, b) => 0.5 - Math.random());

  return (
    <Slide
      arrows={false}
      indicators={false}
      duration={60000}
      pauseOnHover={false}
      canSwipe={false}
    >
      {shuffledImages.map((image) => {
        return (
          <div key={image} className='each-slide-effect'>
            <div style={{ backgroundImage: `url(${image})`, zIndex: 1 }}></div>
          </div>
        );
      })}
    </Slide>
  );
};
