import React, { useState } from 'react';
import './App.css';

import logo from './assets/images/logo.png';
import { SlideShow } from './components/SlideShow';

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Infoscreen } from './views/infoscreen';
import { Statz } from './views/statz';
import { VideoCall } from './views/VideoCall';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <SlideShow />
        <Routes>
          <Route path='/' element={<Infoscreen />}></Route>
          <Route path='statistikk' element={<Statz />}></Route>
          <Route path='videosamtale' element={<VideoCall />}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
