// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBO3Zq8I0Ga6NZ-gdWYDrUbtNE_JxCcC3E',
  authDomain: 'infoscreen-af21e.firebaseapp.com',
  projectId: 'infoscreen-af21e',
  storageBucket: 'infoscreen-af21e.appspot.com',
  messagingSenderId: '474016807360',
  appId: '1:474016807360:web:cb836c70c133ffd3da7025',
  measurementId: 'G-V6L24RTYG8',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db = getFirestore(app);

const analytics = getAnalytics(app);

export { app, db, analytics };
