import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { db } from '../utils/firebase';
import { collection, getDocs, limit, query, orderBy } from 'firebase/firestore';
import { sortAndDeduplicateDiagnostics } from 'typescript';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Saltfjellet',
    },
  },
};

export const VerticalBar = () => {
  const [d, setData] = useState({});

  useEffect(() => {
    try {
      async function FetchData() {
        const da = {};
        const q = query(
          collection(db, 'locations/Polarsirkelsenteret/registrations'),
          orderBy('time', 'desc'),
          limit(1000)
        );
        const docs = await getDocs(q);

        docs.forEach((doc) => {
          const time = new Date();
          const short = moment
            .unix(doc.data().time.seconds)
            .format('MM/DD/YYYY');

          if (short in da) {
            da[short] = [...da[short], ...[short]];
          } else {
            da[short] = [short];
          }
        });

        setData(da);
      }
      FetchData();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }, []);

  const labels = Object.keys(d).map((key) => key);

  const data = {
    labels,
    datasets: [
      {
        label: 'Antall',
        data: Object.values(d).map((key) => key.length),
        backgroundColor: 'rgba(43, 85, 104, 0.5)',
      },
    ],
  };
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        height: '60%',
        width: '50%',
        backgroundColor: 'rgba(235,235,235,.8)',
        margin: 'auto',
      }}>
      <Bar options={options} data={data} />
    </div>
  );
};
