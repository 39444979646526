import { FloatingMessage } from '../components/FloatingMessage';

import { collection, addDoc } from 'firebase/firestore';

import logo from '../assets/images/logo.png';
import { useEffect } from 'react';

import { db } from '../utils/firebase';

export const Infoscreen = () => {
  useEffect(() => {
    try {
      addDoc(collection(db, 'locations/Polarsirkelsenteret/registrations'), {
        time: new Date(),
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }, []);

  return (
    <>
      <div className='logo'>
        <img src={logo} />
      </div>
      <FloatingMessage />
    </>
  );
};
